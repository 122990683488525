import React, {useState, useEffect} from 'react';
import ProductService from "../../service/ProductService";

export const Cart = () => {
    useEffect( ()=>{
        GetProduct()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const [products, setProducts] = useState('')
    const [productsSearch, setProductsSearch] = useState('')
    
    const [mapModify, setMapModify] = useState(new Map())
    const [mapQtServer, setMapQtServer] = useState(new Map()) // Кол-во в корзине с сервера
    
    const [summa, setSumma] = useState(0)

    const [search, setSearch] = useState('')
    const [searchLoad, setSearchLoad] = useState(false)

    const [reply, setReply] = useState('')



    async function GetProduct(){
        const response = await ProductService.getProducts() // Получить товары
        const responseCart = await ProductService.getCart() // Получить товары из корзины пользователя

        console.log(response.data)
        console.log(responseCart.data)

        setProducts(response.data)
        setProductsSearch(response.data)


        for (let i = 0; i < response.data.length; i++) {
            for (let o = 0; o < response.data[i].modify.length; o++) {
              setMapModify(map => new Map(map.set(response.data[i].modify[o].sku, 0))) // Кол-во в корзине до сервера
              setMapQtServer(map => new Map(map.set(response.data[i].modify[o].sku, 0))) // Кол-во в корзине с сервера
            }
        }

        for (let i = 0; i < responseCart.data.length; i++) {
            setMapModify(map => new Map(map.set(responseCart.data[i].sku, responseCart.data[i].qt))) // Кол-во в корзине до сервера
            setMapQtServer(map => new Map(map.set(responseCart.data[i].sku, responseCart.data[i].qt))) // Кол-во в корзине с сервера
        }

        setSumma(0)
        let preSumma = 0
        for (let i = 0; i < response.data.length; i++) {
            for (let o = 0; o < response.data[i].modify.length; o++) {
                for (let p = 0; p < responseCart.data.length; p++) {
                    
                    if (responseCart.data[p].sku === response.data[i].modify[o].sku) {
                        preSumma = preSumma+(Number(response.data[i].modify[o].price)*Number(responseCart.data[p].qt))
                    }
                }
            }
        }
        setSumma(preSumma)
        console.log(preSumma);

    }

    async function CartAll(){

        // Преобразовать в объект
        let mapCart = []
        for (let i = 0; i < productsSearch.length; i++) {
            for (let o = 0; o < productsSearch[i].modify.length; o++) {
                let sku = productsSearch[i].modify[o].sku
                let qt = mapModify.get(productsSearch[i].modify[o].sku)
                if (qt > 0) {
                    mapCart.push({sku, qt})
                }
            }
        }

        // Добавить в корзину
        try {
            const response = await ProductService.addCartAll(mapCart)
            setReply(response.data.message)
        } catch (e) {
            if (e.response.data.message) {
                setReply(e.response.data.message)
            } else {
                setReply(e.message)
            }
        }

        // Обновить корзину кол-во
        const responseCart = await ProductService.getCart()
        for (let i = 0; i < productsSearch.length; i++) {
            for (let o = 0; o < productsSearch[i].modify.length; o++) {
              setMapModify(map => new Map(map.set(productsSearch[i].modify[o].sku, 0))) // Кол-во в корзине до сервера
              setMapQtServer(map => new Map(map.set(productsSearch[i].modify[o].sku, 0))) // Кол-во в корзине с сервера
            }
        }

        for (let i = 0; i < responseCart.data.length; i++) {
            setMapModify(map => new Map(map.set(responseCart.data[i].sku, responseCart.data[i].qt))) // Кол-во в корзине до сервера
            setMapQtServer(map => new Map(map.set(responseCart.data[i].sku, responseCart.data[i].qt))) // Кол-во в корзине с сервера
        }
        
        Summa(responseCart)
    }

    async function Cart(sku){
        const qt = Number(mapModify.get(sku))
        console.log(sku, qt);
 

        //Проверка что больше 0 шт хотят добавить
        if (qt < 0) {
          return setReply('Ошибка, меньше 0 добавить нельзя')
        }


        //Добавить в корзину
        try {
            const response = await ProductService.addCart({qt,sku})
            setReply(response.data.message)
        } catch (e) {
            if (e.response.data.message) {
                setReply(e.response.data.message)
            } else {
                setReply(e.message)
            }
        }


        // //Обновить корзину кол-во
        // const responseCart = await ProductService.getCart()
        // for (let i = 0; i < productsSearch.length; i++) {
        //     for (let o = 0; o < productsSearch[i].modify.length; o++) {
        //       setMapModify(map => new Map(map.set(productsSearch[i].modify[o].sku, 0))) // Кол-во в корзине до сервера
        //       setMapQtServer(map => new Map(map.set(productsSearch[i].modify[o].sku, 0))) // Кол-во в корзине с сервера
        //     }
        // }

        // for (let i = 0; i < responseCart.data.length; i++) {
        //     setMapModify(map => new Map(map.set(responseCart.data[i].sku, responseCart.data[i].qt))) // Кол-во в корзине до сервера
        //     setMapQtServer(map => new Map(map.set(responseCart.data[i].sku, responseCart.data[i].qt))) // Кол-во в корзине с сервера
        // }

        //Обновить корзину кол-во
        const responseCart = await ProductService.getCart()
        for (let i = 0; i < productsSearch.length; i++) {
            for (let o = 0; o < productsSearch[i].modify.length; o++) {
              //setMapModify(map => new Map(map.set(productsSearch[i].modify[o].sku, 0))) // Кол-во в корзине до сервера
              setMapQtServer(map => new Map(map.set(productsSearch[i].modify[o].sku, 0))) // Кол-во в корзине с сервера
            }
        }

        for (let i = 0; i < responseCart.data.length; i++) {
            if (responseCart.data[i].sku === sku) {
                setMapModify(map => new Map(map.set(responseCart.data[i].sku, responseCart.data[i].qt))) // Кол-во в корзине до сервера
            }
            setMapQtServer(map => new Map(map.set(responseCart.data[i].sku, responseCart.data[i].qt))) // Кол-во в корзине с сервера
        }
        
        Summa(responseCart)

    }

    // Сумма заказа дублирующая
    function Summa(responseCart) {
        setSumma(0)
        let preSumma = 0
        for (let i = 0; i < products.length; i++) {
            for (let o = 0; o < products[i].modify.length; o++) {
                for (let p = 0; p < responseCart.data.length; p++) {
                    
                    if (responseCart.data[p].sku === products[i].modify[o].sku) {
                        preSumma = preSumma+(Number(products[i].modify[o].price)*Number(responseCart.data[p].qt))
                    }
                }
            }
        }
        setSumma(preSumma)
        console.log(preSumma);
    }

    async function Order() {
        const response = await ProductService.addOrder()
        GetProduct()
        setReply(response.data)
        console.log(response.data);
    }


    // Поиск
    function Search(search, productsSearch) {
        let products = productsSearch
        
        if (!search) {
            return products;
        }

        return products.filter(({name}) =>
            name.toLowerCase().includes(search.toLowerCase())
        );

    }
  
    useEffect(() => {   
        if (productsSearch.length >= 1) {
            const Debounce = setTimeout(() => {
                const found = Search(search, productsSearch);
                setProducts(found);
              }, 100);
              return () => clearTimeout(Debounce);
        }
    }, [search]);
    // Поиск
    

    return (
        <div className="card g-4">
            <div className="row g-4" style={{marginTop: 5}}>
                <div className="col-12">
                    <>{products && <div>
                        <table className="table table-sm">
                        <thead>
                            <tr>
                                <th style={{width: "15%"}}>Название</th>
                                <th style={{width: "5%"}}>Цена</th>
                                <th style={{width: "5%"}}>Кол-во</th>
                                <th style={{width: "4%"}}>Сохранить</th>
                                <th style={{width: "10%"}}>ИТОГО</th>
                            </tr>

                        </thead><tbody>{products.map(products => 
                            <>{products.modify.map(modify => 
                                <>{mapQtServer.get(modify.sku) > 0 ? 
                                    <tr key={modify.id}>
                                        <td>{modify.name}</td>
                                        <td>{modify.price}₽</td>
                                        <td><input type="number" 
                                            min="0" step="1" 
                                            value={mapModify.get(modify.sku)}
                                            className={`form-control ${mapModify.get(modify.sku) == mapQtServer.get(modify.sku) && mapModify.get(modify.sku) != 0 ? 'is-valid' : ''}`}
                                            onChange={e => setMapModify(map => new Map(map.set(modify.sku, e.target.value)))}
                                        /></td>

                                        <td><button 
                                            className="btn btn-primary" 
                                            onClick={()=>{Cart(modify.sku)}}
                                            disabled={mapModify.get(modify.sku) == mapQtServer.get(modify.sku)}
                                            >Сохранить
                                        </button></td>
                                        
                                        <td>{modify.price*mapModify.get(modify.sku)}₽</td>
                                    </tr>
                                : ''}</>
                            )}</>
                        )}

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><button className="btn btn-primary" onClick={()=>{CartAll()}}>Сохранить все</button></td>
                            <th>{summa}₽</th>
                        </tr>
                        <h1 style={{marginBottom: 25}}>{reply}</h1>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th>
                                <button 
                                    className="btn btn-success" 
                                    onClick={Order}
                                    >Оформить заказ
                                </button>
                            </th>
                        </tr>
                        
                        </tbody></table>

{/* <table className="table table-sm">
<thead>
<tr>
<th style={{width: "15%"}}> </th>
<th style={{width: "5%"}}> </th>
<th style={{width: "5%"}}> </th>
<button style={{width: "20%"}} onClick={''}> Оформить заказ</button>
<th style={{width: "10%"}}>1000р</th>
</tr>
</thead>
<tbody>

</tbody>
</table> */}

                    </div>}</>
                </div>

            </div>
        </div>

    )
}