import React, {useState} from 'react';

export const RestorePassword = () => {
    const [email, setEmail] = useState('')
    const [reply, setReply] = useState('')
    const [loading, setLoading] = useState(false)

    
    async function login() {
        setLoading(true)
        if (!email) {
            setLoading(false)
            return setReply('Укажите email');
        }

        setLoading(false)
        return setReply('Инструкция по восстановлению пароля придет на ваш email');
    }


    async function KeyUp(event) {
        if (!loading && event.key === 'Enter'){
            return login()
        }
    }

    return (
        <div className='col-md-5 mx-auto' style={{marginTop: 100}}>
            <div className="box">
            <h4>В разработке</h4>
            
            {/* <input
                disabled={loading}
                className="form-control"
                onChange={e => setEmail(e.target.value)}
                value={email}
                type="text"
                placeholder='E-mail'
                style={{marginTop: 10}}
                onKeyUp={KeyUp}
            />

            <h6 style={{marginTop: 10}}>{reply}</h6>

            <button
                disabled={loading}
                className='btn btn-primary' 
                style={{marginTop: 10, marginRight: 10}} 
                onClick={login}>
                Восстановить пароль
            </button> */}

            <a className='btn btn-outline-secondary' style={{marginTop: 10, marginRight: 10}} href="/auth">Войти</a>
            <a className='btn btn-outline-secondary' style={{marginTop: 10}} href="/register">Регистрация</a>

            </div>
        </div>
    )
}