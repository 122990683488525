import React, {useState, useEffect} from 'react';
import ProductService from "../../service/ProductService";

export const Shop = () => {
    
  useEffect( ()=>{
      MapProduct()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const [namesProducts, setNamesProducts] = useState('')
  const [products, setProducts] = useState('')
  const [mapModify, setMapModify] = useState(new Map())
  const [mapReply, setMapReply] = useState(new Map())
  const [poisk, setPoisk] = useState('')
  
  const [x, setX] = useState(false)

  const [summa, setSumma] = useState(1)
  const [summa1, setSumma1] = useState(`РРЦ🟥 - 0₽`)
  const [summa2, setSumma2] = useState(`ОПТ1🟥 - 0₽`)
  const [summa3, setSumma3] = useState(`ОПТ2🟥 - 0₽`)
  
  const [load, setLoad] = useState(false)

  async function MapProduct(){
    const response = await ProductService.getProducts()
    
    console.log(response.data);

    setProducts(response.data)
    setNamesProducts(response.data)

    for (let i = 0; i < response.data.length; i++) {
      for (let o = 0; o < response.data[i].modify.length; o++) {
        setMapModify(map => new Map(map.set(response.data[i].modify[o].sku, 0)))
        setMapReply(map => new Map(map.set(response.data[i].modify[o].sku, '')))
      }
    }

    MapCart(response.data)
  }


  async function Cart(sku){

    //Чистим все ответы
    for (let i = 0; i < namesProducts.length; i++) {
      for (let o = 0; o < namesProducts[i].modify.length; o++) {
        setMapReply(map => new Map(map.set(namesProducts[i].modify[o].sku, '')))
      }
    }

    //Проверка что больше 0 шт хотят добавить
    const qt = Number(mapModify.get(sku))
    if (qt < 0) {
      return setMapReply(map => new Map(map.set(sku, 'Менее 1 шт')))
    }

    //Добавить в корзину
    setLoad(true)
    try {
      const response = await ProductService.addCart({qt,sku})
      setMapReply(map => new Map(map.set(sku, response.data.message)))
    } catch (e) {
      console.log(e);
        if (e.response.data.message) {
            setMapReply(map => new Map(map.set(sku, e.response.data.message)))
        } else {setMapReply(map => new Map(map.set(sku, e.message)))}
    } finally {
      MapCart(namesProducts)
      OnlyCart()
      setLoad(false)
    }
    
  }


  async function MapCart(productsMap) {

    for (let i = 0; i < productsMap.length; i++) {
      for (let o = 0; o < productsMap[i].modify.length; o++) {
        setMapModify(map => new Map(map.set(productsMap[i].modify[o].sku, 0)))
      }
    }

    const responseCart = await ProductService.getCart()
    for (let i = 0; i < responseCart.data.length; i++) {
      setMapModify(map => new Map(map.set(responseCart.data[i].sku, responseCart.data[i].qt)))
    }

    let prRRC = 0
    let prOPT = 0
    let prOPT2 = 0

    for (let i = 0; i < productsMap.length; i++) {
      for (let o = 0; o < productsMap[i].modify.length; o++) {
        for (let p = 0; p < responseCart.data.length; p++) {
          if (responseCart.data[p].sku === productsMap[i].modify[o].sku) {
            prRRC = prRRC+(Number(productsMap[i].modify[o].price)*Number(responseCart.data[p].qt))
            prOPT = prOPT+(Number(productsMap[i].modify[o].price2)*Number(responseCart.data[p].qt))
            prOPT2 = prOPT2+(Number(productsMap[i].modify[o].price3)*Number(responseCart.data[p].qt))
          }
        }
      }
    }

    if (prOPT2 >= 100000) {
      setSumma(3)
      setSumma1(`РРЦ 🟥 - ${prRRC}₽`)
      setSumma2(`ОПТ1🟥 - ${prOPT}₽`)
      setSumma3(`ОПТ2🟩 - ${prOPT2}₽`)
    } else {
      if (prOPT >= 60000) {
        setSumma(2)
        setSumma1(`РРЦ 🟥 - ${prRRC}₽`)
        setSumma2(`ОПТ1🟩 - ${prOPT}₽`)
        setSumma3(`ОПТ2🟥 - ${prOPT2}₽`)
      } else {
        setSumma(1)
        setSumma1(`РРЦ 🟩 - ${prRRC}₽`)
        setSumma2(`ОПТ1🟥 - ${prOPT}₽`)
        setSumma3(`ОПТ2🟥 - ${prOPT2}₽`)
      }
    }

  }

  
  function OnlyCart(){
    setPoisk('')
    if (x) {
      console.log(mapModify);

      let cartProduct = []

      for (let i = 0; i < namesProducts.length; i++) {
        let modify = []
        for (let o = 0; o < namesProducts[i].modify.length; o++) {
          if (mapModify.get(namesProducts[i].modify[o].sku)>0) {
            modify.push(namesProducts[i].modify[o])
          }
        }
        if (modify.length > 0) {
          cartProduct.push({
            id: namesProducts[i].id,
            name: namesProducts[i].name,
            modify: modify
          }
          )
        }
      }
      setProducts(cartProduct)
      //setNamesProducts(cartProduct)
      return
    } 

    return setProducts(namesProducts)
    //return MapProduct()
  }
  
  useEffect(() => {   
    OnlyCart()
  }, [x]);

  function Poisk(poisk, namesProducts) {
      let filterNamesProducts = namesProducts
      if (!poisk) {
          return filterNamesProducts;
      }

      return filterNamesProducts.filter(({name}) =>
          name.toLowerCase().includes(poisk.toLowerCase())
      );
  }

  useEffect(() => {   
      if (namesProducts.length >= 1) {
          const Debounce = setTimeout(() => {
              const PoiskS = Poisk(poisk, namesProducts);
              setProducts(PoiskS);
            console.log(PoiskS);
            }, 100);
            return () => clearTimeout(Debounce);
      }

  }, [poisk]);
  //// Поиск

  async function newOrder(){
    const response = await ProductService.addOrder()
    MapProduct()
    setX(false)
    console.log(response);
  }

    return (

        <div className="row g-4">

          <div className="col-12 fixed-filter">
            
          <input 
            className="form-control" 
            value={poisk}
            onChange={(e)=> setPoisk(e.target.value)}
            placeholder='Поиск'
            disabled={x}
            />
          </div>
            
          <div className="col-2 ">
            <div className="box fixed-filter" style={{top: "80px"}}>
              <h1>{summa1}</h1>
              <h1>{summa2}</h1>
              <h1>{summa3}</h1>

              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={x} onChange={() => setX(!x)}/>
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Корзина</label> 
              </div>

            </div>

            <div className="box fixed-filter" style={{top: "230px"}}>
              <h1>Тут будет фильтр</h1>
            </div>

          </div>  




          <div className="col-10">
          {products &&
            <>
              {products.map(products =>
                <div className="card mb-3 accordion" id="accordionPanelsStayOpenExample" key={products.id}>
                  <div className="accordion-item"> 
                  <h2 className="accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      {products.name}
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                    
                    <div className="accordion-body">
                      <table className="table table-sm">
                        <thead>
                            <tr>
                                <th style={{width: "20%"}}>Название</th>
                                <th style={{width: "5%"}}>РРЦ</th>
                                {/* <th style={{width: "5%"}}>Опт-1</th>
                                <th style={{width: "5%"}}>Опт-2</th> */}
                                {/* <th style={{width: "5%"}}>Ост.</th> */}
                                <th style={{width: "5%"}}>Кол-во</th>
                                <th style={{width: "5%"}}>Сохранить</th>
                                {/* <th style={{width: "10%"}}>Ответ</th> */}
                                <th style={{width: "10%"}}>ИТОГО</th>
                            </tr>
                        </thead>
                        <tbody>
                          {products.modify.map(modify =>
                              <tr key={modify.id}>
                                  <td>{modify.name}</td>
                                  <td>{modify.price}₽</td>
                                  {/* <td>{modify.price2}₽</td>
                                  <td>{modify.price3}₽</td> */}
                                  {/* <td>🟥</td> */}
                                  <td>
                                      <input type="number" 
                                        min="0" step="1"
                                        value={mapModify.get(modify.sku)}
                                        className="form-control"
                                        onChange={e => setMapModify(map => new Map(map.set(modify.sku, e.target.value)))}
                                        disabled={load}
                                      />
                                  </td>
                                  <td><button className="btn btn-primary" onClick={()=>{Cart(modify.sku)}} disabled={load}>Сохранить</button></td>
                                  {/* <td>{mapReply.get(modify.sku)}</td> */}
                                  <td>{
                                  summa==1 ? `${modify.price*mapModify.get(modify.sku)}` : 
                                  summa==2 ? `${modify.price2*mapModify.get(modify.sku)}` : 
                                              `${modify.price3*mapModify.get(modify.sku)}`
                                      }</td> 
                              </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            )}
          </>
        }
      {x ?
      <div className="card mb-3 accordion">
      <div className="accordion-body">
<table className="table table-sm">
<thead>
<tr>
<th style={{width: "20%"}}>{summa1}</th>
<th style={{width: "20%"}}>{summa2}</th>
<th style={{width: "20%"}}>{summa3}</th>
<button style={{width: "20%"}} onClick={newOrder}> Оформить заказ</button>
</tr>
</thead>
<tbody>

</tbody>
</table>


      </div>
      </div>
      : ''}

      </div>
    </div>
    )
}

//🟥🟧🟨🟩