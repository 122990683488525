import {useContext} from "react";



//import {Switch, Route, Redirect} from 'react-router-dom'
import {Routes, Route, Navigate} from 'react-router-dom'




// Import pages auth
import {Auth} from './pages/auth/auth';
import {Register} from './pages/auth/register';
import {RestorePassword} from './pages/auth/restorePassword';
import {Activated} from './pages/auth/activated';


//Activated
// Import pages all
import {Home} from './pages/home/home';
import {Shop} from './pages/home/shop';
import {Cart} from './pages/home/cart';


import {Context} from ".";

export const useRoutes = ({isAuthenticated, isActivated, isLoading}) => {
  

  const token = localStorage.getItem('token')
  const {store} = useContext(Context)
 
  if (isAuthenticated && isActivated) {
      return (
        <Routes>
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/shop" element={<Shop />} />
            <Route exact path="/cart" element={<Cart />} />
            <Route path="*" element={<Navigate to="/home" replace />}/>
        </Routes>
      )
    }
  
    if (isAuthenticated && !isActivated) {
      return (
        <Routes>
            <Route exact path="/activated" element={<Activated />} />
            <Route path="*" element={<Navigate to="/activated" replace />}/>
        </Routes>
      )
    }

    if (token) {
        return (
          <Routes>
            <Route path="/*" exact>
            </Route>
            <Route path="/*" element={<Navigate to="/*" replace />}/>
          </Routes>
        )
    }

    return (
      <Routes>
          <Route exact path="/auth" element={<Auth />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/restorePassword" element={<RestorePassword />} />
          <Route path="*" element={<Navigate to="/auth" replace />}/>
      </Routes>
    )

}
  









// import React, { useEffect } from 'react';
// import {Routes,Route,useLocation,Navigate} from 'react-router-dom';

// // Import pages auth
// import {Auth} from './pages/auth/auth';
// import {Register} from './pages/auth/register';
// import {RestorePassword} from './pages/auth/restorePassword';

// // Import pages all
// import {Home} from './pages/home/home';


// export const useRoutes = (isAuthenticated,isLoading) => {

//     const location = useLocation();
//     useEffect(() => {
//       document.querySelector('html').style.scrollBehavior = 'auto'
//       window.scroll({ top: 0 })
//       document.querySelector('html').style.scrollBehavior = ''
//     }, [location.pathname]); // triggered on route change

//     // All routes
//     if(isAuthenticated){
//         return(
//             <>
//                 <Routes>
//                     <Route exact path="/" element={<Home />} />
//                     <Route path="*" element={<Navigate to="/" replace />}/>
//                 </Routes>
//             </>
//         )
//     }

//     // Authenticated routes
//     return(
//         <>
//             <Routes>
//                 <Route exact path="/auth" element={<Auth />} />
//                 <Route exact path="/register" element={<Register />} />
//                 <Route exact path="/restorePassword" element={<RestorePassword />} />
//                 <Route path="*" element={<Navigate to="/auth" replace />}/>
//             </Routes>
//         </>
//     )

// }