import $api from "../http";

export default class ProductService {

    static async getProducts(){
        return $api.post('/product/getproduct')
    }
    
    static async getCart(){
        return $api.post('/product/getcart')
    }

    static async addCart({qt,sku,price}){
        return $api.post('/product/addcart', {qt,sku,price})
    }

    static async addCartAll(mapCart){
        return $api.post('/product/addcartall', {mapCart})
    }

    static async addOrder(){
        return $api.post('/product/addorder')
    }

    
}

