import React, {useContext, useState} from 'react';
import {Context} from "../../index";
//import ComponentWithCaptcha from "../../components/SmartCaptcha"
import { SmartCaptcha } from '@yandex/smart-captcha';


export const Auth = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [reply, setReply] = useState('')
    const [loading, setLoading] = useState(false)
    const {store} = useContext(Context);

    const [token, setToken] = useState('');


    async function login() {
        setReply('')
        setLoading(true)
        // if (!token) {
        //     setLoading(false)
        //     return setReply('Чтобы войти, пройдите капчу');
        // }
        if (!email) {
            setLoading(false)
            return setReply('Чтобы войти, укажите email');
        }
        if (!password) {
            setLoading(false)
            return setReply('Чтобы войти, укажите пароль');
        }

        const response = await store.login(email, password)
        setReply(response)
        return setLoading(false)
    }


    async function KeyUp(event) {
        if (!loading && event.key === 'Enter'){
            return login()
        }
    }

    return (

        <div className='col-md-5 mx-auto' style={{marginTop: 100}}>
            <div className="box" style={{padding: 48}}>
            <h4>Вход в KOpt</h4>
            
            <input
                disabled={loading}
                className="form-control"
                onChange={e => setEmail(e.target.value)}
                value={email}
                type="text"
                placeholder='E-mail'
                style={{marginTop: 10}}
                onKeyUp={KeyUp}
            />

            <input
                disabled={loading}
                className="form-control"
                onChange={e => setPassword(e.target.value)}
                value={password}
                type="password"
                placeholder="Пароль"
                style={{marginTop: 10}}
                onKeyUp={KeyUp}
            />

            <h6 style={{marginTop: 10}}>{reply}</h6>
            {/* <SmartCaptcha sitekey="ysc1_XpA2Yw6OSc3nRp5hpo9qyv9fp176EznJLTYcOfOcd6780898" onSuccess={setToken} /> */}
            <button
                disabled={loading}
                className='btn btn-primary' 
                style={{marginTop: 10, marginRight: 10}} 
                onClick={login}>
                Войти
            </button>

            <a className='btn btn-outline-secondary' style={{marginTop: 10, marginRight: 10}} href="/register">Регистрация</a>
            {/* <a className='btn btn-outline-secondary' style={{marginTop: 10}} href="/restorePassword">Забыли пароль?</a> */}

            </div>


            
        </div>
    )
}