import React, {useContext, useState} from 'react';
import {Context} from "../../index";

export const Register = () => {
    const [inn, setInn] = useState('')
    const [fio, setFio] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [reply, setReply] = useState('')
    const [loading, setLoading] = useState(false)
    const {store} = useContext(Context);
    
    async function registration() {
        setReply('')
        setLoading(true)
        if (!inn) {
            setLoading(false)
            return setReply('Чтобы зарегистрироватья, укажите ИНН');
        }
        if (!fio) {
            setLoading(false)
            return setReply('Чтобы зарегистрироватья, укажите ФИО/Наименование организации');
        }
        if (!email) {
            setLoading(false)
            return setReply('Чтобы зарегистрироватья, укажите email');
        }
        if (!password) {
            setLoading(false)
            return setReply('Чтобы зарегистрироватья, укажите пароль');
        }
        const code = 'oF66amJEjk'
        const response = await store.registration(email, password, code, inn, fio)
        setLoading(false)
        return setReply(response);
    }


    async function KeyUp(event) {
        if (!loading && event.key === 'Enter'){
            return registration()
        }
    }

    return (
        
        <div className='col-md-5 mx-auto' style={{marginTop: 100}}>
            
            

            <div className="box">
            <h4>Регистрация в KOpt</h4>
            
            <input
                disabled={loading}
                className="form-control"
                onChange={e => setFio(e.target.value)}
                value={fio}
                type="text"
                placeholder='ФИО/Наименование организации'
                style={{marginTop: 10}}
                onKeyUp={KeyUp}
            />

            <input
                disabled={loading}
                className="form-control"
                onChange={e => setInn(e.target.value)}
                value={inn}
                type="text"
                placeholder='ИНН'
                style={{marginTop: 10}}
                onKeyUp={KeyUp}
            />

            <input
                disabled={loading}
                className="form-control"
                onChange={e => setEmail(e.target.value)}
                value={email}
                type="text"
                placeholder='E-mail'
                style={{marginTop: 10}}
                onKeyUp={KeyUp}
            />

            <input
                disabled={loading}
                className="form-control"
                onChange={e => setPassword(e.target.value)}
                value={password}
                type="password"
                placeholder="Пароль"
                style={{marginTop: 10}}
                onKeyUp={KeyUp}
            />

            <h6 style={{marginTop: 10}}>{reply}</h6>

            <button
                disabled={loading}
                className='btn btn-primary' 
                style={{marginTop: 10, marginRight: 10}} 
                onClick={registration}>
                Зарегистрироваться
            </button>

            <a className='btn btn-outline-secondary' style={{marginTop: 10, marginRight: 10}} href="/auth">Войти</a>
            {/* <a className='btn btn-outline-secondary' style={{marginTop: 10}} href="/restorePassword">Забыли пароль?</a> */}
            
            <h1 style={{marginTop: 10}}>Нажимая на кнопку "Зарегистрироваться", вы даете согласие на обработку своих персональных данных и соглашаетесь с <a href='https://bykaori.ru/privacy'>политикой конфиденциальности</a></h1>

            </div>

            <div className="box" style={{marginTop: 100}}>
                <h1>Уважаемые партнеры! У нас вышло обновление оптового портала.
                Для дальнейшей работы просьба зарегистрироваться тут.
                После регистрации напишите своему менеджеру для получения полного доступа к порталу. 
                Ему понадобится Ваш ИНН, указанный при регистрации.</h1>
            </div>

        </div>
    )
}